import { Table, Top, Cell, Row, Pagination } from 'components/Table';
import { isMobile } from 'react-device-detect';
import {
  ENTITIES_COLUMNS,
  QUIZZES_TYPE,
  STATISTICS_ENTITIES,
  VIDEOS_TYPE,
} from 'constants/analytics';
import { useContext, useEffect, useState } from 'react';
import { AnalyticsContext } from 'contexts/analytics';
import {
  OptionsSection,
  EntitiesWrapper,
  EntityButton,
  TableContainer,
  EmptyTable,
  TableWrapper,
} from './styles';
import './statics.scss';
import { Spinner } from 'components/loadings';
import Icon from 'components/icons';
import RowMobile from './RowMobile';
import TableCellContent from './TableCellContent';

const EnititesTable = (): JSX.Element => {
  const { actions, currentChart, entitiesTable, tableOrdering } = useContext(AnalyticsContext);
  const [order, setOrder] = useState(0);
  const activeUsersColumnsTableConfig = isMobile
    ? '90% 1fr'
    : currentChart === QUIZZES_TYPE.VALUE.toLowerCase() ||
      currentChart === VIDEOS_TYPE.VALUE.toLowerCase()
    ? '1fr 1fr 1fr 1fr'
    : '1fr 1fr 1fr';
  const columnsMetadata = ENTITIES_COLUMNS[currentChart.toUpperCase()][entitiesTable.currentEntity];

  const updateSelectedEntity = (value: string) => {
    actions && actions.onUpdateEntity(value);
  };
  const isEntitySelected = (entity: string) => {
    return entitiesTable.currentEntity === entity;
  };
  const showIcon = (index, value) => {
    return (
      currentChart === QUIZZES_TYPE.VALUE.toLowerCase() && index === 3 && value > 0 && !isMobile
    );
  };
  const toggleOrder = () => {
    // TODO: impelement order
    const newOrder = order ? order * -1 : 1;
    setOrder(newOrder);
  };
  return (
    <TableWrapper loading={entitiesTable.loading}>
      <Spinner
        active={entitiesTable.loading}
        top={isMobile ? '170px' : '270px'}
        position="relative"
      />
      <OptionsSection lock={entitiesTable.loading}>
        <EntitiesWrapper>
          {STATISTICS_ENTITIES.map(({ LABEL: label, VALUE: value }) => {
            const isSelected = isEntitySelected(value);
            return (
              <EntityButton
                active={isSelected}
                key={`entity-${value}`}
                onClick={() => updateSelectedEntity(value)}
              >
                <span>{label.toUpperCase()}</span>
              </EntityButton>
            );
          })}
        </EntitiesWrapper>
      </OptionsSection>
      <TableContainer lock={entitiesTable.loading}>
        {entitiesTable?.items?.length != 0 ? (
          <Table>
            <Row
              className="entities-table__table-header"
              columns={activeUsersColumnsTableConfig}
              top={true}
            >
              {isMobile ? (
                <Top>{columnsMetadata[0].label.toUpperCase()}</Top>
              ) : (
                columnsMetadata.map((column, index) => (
                  <Top key={index}>
                    {column.label.toUpperCase()}
                    {/* {index===0 && <ButtonIcon
                      style={{margin:'0px 4px', minWidth:'30px'}}
                      $terciary
                      size={'small'}
                      icon={order && order == -1 ? 'SortDown' : 'Sort'}
                      onClick={() => toggleOrder()}
                    />} */}
                  </Top>
                ))
              )}
            </Row>
            {entitiesTable?.items?.map((item, index) => (
              <Row columns={activeUsersColumnsTableConfig} key={index}>
                {columnsMetadata.map((column, index) => (
                  <Cell className={column.name} key={index}>
                    <TableCellContent
                      key={index}
                      column={column}
                      index={index}
                      entity={entitiesTable.currentEntity}
                      item={item}
                      showValues={!isMobile}
                      card={false}
                    />
                    {showIcon(index, column.value(item)) && (
                      <Icon
                        icon={'Checked'}
                        fill="#65AAA3"
                        style={{ width: '10px', height: '7px' }}
                      />
                    )}
                  </Cell>
                ))}
                <Cell hidden={!isMobile} className="entities-table__table-mobile-details">
                  <RowMobile
                    data={item}
                    metadata={columnsMetadata}
                    entity={entitiesTable.currentEntity}
                  ></RowMobile>
                </Cell>
              </Row>
            ))}
          </Table>
        ) : (
          <EmptyTable>
            <div className="entities-table__empty_title">No data available</div>
            <div className="entities-table__empty_subtitle">
              No {entitiesTable.currentEntity} data available yet
            </div>
          </EmptyTable>
        )}
        {entitiesTable.items && entitiesTable.items.length != 0 && (
          <Pagination
            count={entitiesTable.count}
            page={entitiesTable.page}
            page_size={entitiesTable.pageSize}
            onPagesClick={(value, name) => actions.onChangePagination({ name: name, value })}
          />
        )}
      </TableContainer>
    </TableWrapper>
  );
};

export default EnititesTable;
