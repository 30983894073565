import { createElement, useContext, useEffect, useState } from 'react';
import HeaderInfo from './headerInfo';
import HeaderToolBar from './headerToolBar';
import { SessionContext } from 'contexts/session';
import styled from 'styled-components';
import { screenMd } from 'constants/styles/breakpoints';
import { HeaderLoading } from 'components/loadings';
import { isMobile } from 'react-device-detect';
import usePath from 'hooks/usePath';
import './header.scss';

const Container = styled.div<{ translateY: string }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1056;
  height: 64px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.default.colors.header.bg};
  box-shadow: 0px 1px 0px ${({ theme }) => theme.default.colors.body.bg};
  transition: top 0.3s;
  ${screenMd} {
    left: 140px;
    z-index: 900;
  }

  ${({ translateY }) =>
    translateY &&
    translateY !== '0px' &&
    `
		top: ${translateY}
	`}
`;

const Header = ({ translateY }: { translateY: string }): JSX.Element => {
  const { account, loading, showDrawer, showActivityLogDrawer, showNotesDrawer } =
    useContext(SessionContext);
  const loadingRef = createElement<{ id: string }>('div', { id: 'react-header-loading' }, '');
  const [componentLoad, setComponentLoad] = useState(false);
  const { title } = usePath();

  useEffect(() => {
    setComponentLoad(true);
  }, []);

  return (
    <>
      <Container
        translateY={(!showDrawer || !showNotesDrawer || !showActivityLogDrawer) && translateY}
      >
        <HeaderInfo title={isMobile ? title : account?.name} />
        <HeaderToolBar />
        {loadingRef}
        {componentLoad && <HeaderLoading portals={true} active={loading} />}
      </Container>
    </>
  );
};

export default Header;
