import { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { AnalyticsContext } from 'contexts/analytics';
import AnalyticsFilters from '../AnalyticsFilters';
import AnalyticsTeamsFilter from '../AnalyticsTeamsFilter';
import ActiveUsersCollapse from '../ActiveUsersCollapse';
import ActiveUsersStatsPanel from '../ActiveUsersStatsPanel';
import { Container, SectionCard } from './styles';

const AnalyticsContent = (): JSX.Element => {
  const { activities, counters, actions, currentChart, entitiesTable } =
    useContext(AnalyticsContext);
  return (
    <>
      <Container>
        <SectionCard padding={'10px'}>
          <AnalyticsTeamsFilter />
        </SectionCard>

        <SectionCard padding={'10px'}>
          <AnalyticsFilters />
        </SectionCard>

        <SectionCard rounded={!isMobile} padding={isMobile ? '0' : '30px'} shadow>
          <ActiveUsersCollapse />
        </SectionCard>
        <SectionCard padding={isMobile ? '0' : '30px'} rounded={!isMobile} shadow>
          <ActiveUsersStatsPanel
            activities={activities}
            actions={actions}
            counters={counters}
            tabSelected={currentChart}
            entitiesTable={entitiesTable}
          />
        </SectionCard>
      </Container>
    </>
  );
};

export default AnalyticsContent;
