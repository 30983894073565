import { screenLg, screenMd, screenSm } from 'constants/styles/breakpoints';

import styled from 'styled-components';

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${screenMd} {
    //min-height: 50vh;
  }
`;

export const HeaderRow = styled.div`
  display: flex;
  height: 30px;
  align-items: center;
`;

export interface TableRowProps {
  disabled?: boolean;
  columns: string;
  transparent?: boolean;
  top?: boolean;
  topSelect?: boolean;
  sticky?: boolean;
  impersonated?: boolean;
  noCtaHeader?: boolean;
}

export const Row = styled.div<TableRowProps>`
  border-bottom: 1px solid
    ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyLighter};
  min-height: 70px;
  height: auto;
  align-items: center;
  padding: 0 10px;
  margin: 2px 0px;
  background: #ffffff;
  border-radius: 10px;
  ${screenSm} {
    min-height: 80px;
    height: auto;
    background: transparent;
    border-radius: 0px;
    padding: 0;
    margin: 0;
    ${({ disabled }) =>
      disabled &&
      `
		background: #f2f2f74a !important;
		`}
  }

  ${({ transparent }) =>
    transparent &&
    `
		background: transparent;
	`}

  ${({ top }) =>
    top &&
    `
		min-height: unset;
    padding: 15px 10px 15px;
		${screenLg} {
			min-height: unset;
			border: none;
      padding: 40px 10px 15px;
		}
	`}

${({ noCtaHeader }) =>
    noCtaHeader &&
    `
    padding: 0 0 20px 0;
		${screenSm} {
      padding: 0 0 20px 0;
		}
	`}

	${({ topSelect }) =>
    topSelect &&
    `
		height: auto;
		padding-bottom: 10px;
		width: 200px;
		${screenMd} {
			width: 100%;
		}
	`}

	${({ sticky, impersonated }) =>
    sticky &&
    `
		${screenMd} {
			position: sticky;
			top: ${impersonated ? '130px' : '65px'};
			background: #FFF !important;
			z-index: 99;
		}
	`}

	display: grid;
  grid-template-columns: 40px 6fr 40px;
  ${screenMd} {
    grid-template-columns: ${({ columns }) => columns || '43px 6fr 4fr 2fr 2fr 2fr 90px'};
  }
`;

export const Top = styled.div<{ topSelect?: boolean; hasSubcolumn?: boolean }>`
  display: flex;
  width: 100%;
  white-space: nowrap;
  align-items: center;
  padding: 0 2px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyDarker};
  position: relative;
  ${({ topSelect }) =>
    topSelect &&
    `
		width: 260px;
	`}
  ${({ hasSubcolumn }) =>
    hasSubcolumn &&
    `
    align-items: flex-start;
		flex-direction: column;
    margin-top: 17px;
    gap: 5px;
    span {
      padding: 0px 3px 0px 3px;
    }
	`}
`;

export const Cell = styled.div<{
  hidden?: boolean;
}>`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  padding: 6px 3px;
`;

export const EmptyCell = styled.div`
  width: 40px;
  height: 4px;
  border-radius: 40px;
  margin: 0;
  background-color: ${({ theme }) => theme.default.colors.palette.Business_LightThemeGreyLighter};
`;
