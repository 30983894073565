import { useContext } from 'react';
import styled from 'styled-components';

import AnalyticsProvider from 'contexts/analytics';
import { SessionContext } from 'contexts/session';

import AnalyticsContent from './components/AnalyticsContent/AnalyticsContent';
import AnalyticsEmpty from './components/AnalyticsEmpty';

import { ROLES } from 'constants/roles';

const PageContent = styled.div`
  background: '#F2F2F7';
`;

const Analytics = (): JSX.Element => {
  const { userRoleIds, account } = useContext(SessionContext);
  const isAccOwner = userRoleIds.includes(ROLES.ACCOUNT_OWNER);

  return (
    <>
      {isAccOwner && Number(account.users_count) === 0 ? (
        <PageContent>
          <AnalyticsEmpty />
        </PageContent>
      ) : (
        <AnalyticsProvider>
          <PageContent>
            <AnalyticsContent />
          </PageContent>
        </AnalyticsProvider>
      )}
    </>
  );
};

export default Analytics;
