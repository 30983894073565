import { useContext, useEffect, useState } from 'react';
import { EnitiesTableDetails } from './styles';
import Tooltip from 'components/tooltip';
import ButtonIcon from 'components/buttonIcon';
import ModalDrawer from 'components/microLayouts/drawer';
import { SessionContext } from 'contexts/session';
import { IEntitiesTableDetails } from 'contexts/analytics/types';
import TableCellContent from './TableCellContent';
import { COURSES_ENTITY, TEAMS_ENTITY } from 'constants/analytics';

const RowMobile = ({ data, metadata, entity }: IEntitiesTableDetails): JSX.Element => {
  const { showDrawer, setVisibiltyDrawer } = useContext(SessionContext);
  const [selectedItem, setSelectedItem] = useState(null);
  const toggle = () => {
    setSelectedItem(data);
    setVisibiltyDrawer(!showDrawer);
  };

  useEffect(() => {
    !showDrawer && setSelectedItem(null);
  }, [showDrawer]);

  return (
    <>
      <Tooltip placement="left" title="Go to details">
        <ButtonIcon
          $terciary
          icon="ChevronRight"
          size="small"
          onClick={() => {
            toggle();
          }}
        />
      </Tooltip>
      <ModalDrawer
        closable={false}
        placement="bottom"
        visible={showDrawer && selectedItem === data}
        onClose={toggle}
        height="calc(100% - 64px)"
        destroyOnClose
      >
        <EnitiesTableDetails.Background>
          <EnitiesTableDetails.Header bottom={entity === COURSES_ENTITY.VALUE ? '20px' : '130px'}>
            <EnitiesTableDetails.Title>
              <TableCellContent
                column={metadata[0]}
                index={0}
                entity={entity}
                item={selectedItem}
                showValues={false}
                card={true}
              />
            </EnitiesTableDetails.Title>
          </EnitiesTableDetails.Header>
          <EnitiesTableDetails.Content>
            {metadata.slice(1).map((column, index) => (
              <EnitiesTableDetails.Row.Container key={index}>
                <EnitiesTableDetails.Row.Title>
                  {column.label.toUpperCase()}
                </EnitiesTableDetails.Row.Title>
                <EnitiesTableDetails.Row.Separator></EnitiesTableDetails.Row.Separator>
                <EnitiesTableDetails.Row.Value>
                  <TableCellContent
                    column={column}
                    index={index + 1}
                    entity={entity}
                    item={selectedItem}
                    showValues={true}
                    card={true}
                  />
                </EnitiesTableDetails.Row.Value>
              </EnitiesTableDetails.Row.Container>
            ))}
          </EnitiesTableDetails.Content>
        </EnitiesTableDetails.Background>
      </ModalDrawer>
    </>
  );
};
export default RowMobile;
