import { useContext, useEffect, useState } from 'react';
import { AnalyticsDataCtx, IFilterItem } from 'contexts/analytics/types';
import { analyticsInitialValues } from 'contexts/analytics/utils';
import {
  getActiveUsersAction,
  getAgregationAction,
  getCoursesTableAction,
  getSeriesByTypeAction,
  getTeamsTableAction,
  getUsersTableAction,
} from 'contexts/analytics/actions';
import cloneDeep from 'lodash.clonedeep';

import {
  ACTIVITIES_TYPE,
  COURSES_ENTITY,
  ENTITY_COLUMN_ACTIONS,
  FINISHED_STATUS,
  OVERALL_STATUS,
  PASSED_STATUS,
  QUERIES_BY_TYPE,
  QUIZZES_TYPE,
  STARTED_STATUS,
  TEAMS_ENTITY,
  USERS_ENTITY,
  VIDEOS_TYPE,
  WATCHED_STATUS,
} from 'constants/analytics';
import { SessionContext } from 'contexts/session';

interface AnalyticsContentHook {
  fetching: boolean;
  isEmpty: boolean;
  filters: AnalyticsDataCtx['filters'];
  tableOrdering: AnalyticsDataCtx['tableOrdering'];
  activitiesChart: AnalyticsDataCtx['activities'];
  activeUsersChart: AnalyticsDataCtx['activeUsers'];
  entitiesTable: AnalyticsDataCtx['entitiesTable'];
  counters: AnalyticsDataCtx['counters'];
  error: string | boolean;
  currentChart: string;
  updateChart: (currentChart: string) => void;
  updateEntity: (currentEntity: string) => void;
  changeFilters: (newValue: IFilterItem) => void;
  changePagination: (value: { name: string; value: string }) => void;
  changeOrdering: (newValue: string) => void;
}

const useAnalyticsContent = (): AnalyticsContentHook => {
  const [filters, setFilters] = useState(analyticsInitialValues.filters);
  const [tableOrdering, setOrdering] = useState(analyticsInitialValues.tableOrdering);
  const [activitiesChart, setActivitiesChart] = useState(analyticsInitialValues.activities);
  const [activeUsersChart, setActiveUsersChart] = useState(analyticsInitialValues.activeUsers);
  const [entitiesTable, setEntitiesTable] = useState(analyticsInitialValues.entitiesTable);
  const [counters, setCounters] = useState(analyticsInitialValues.counters);
  const [fetching, setFetching] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [currentChart, setCurrentChart] = useState(analyticsInitialValues.currentChart);

  const { user, account, teams, isAccountOwner } = useContext(SessionContext);
  const business_account_id = account?.id;
  const uaa_id = user.uaa_id;

  const getActivitiesChartData = async ({ params }, type?: string) => {
    try {
      const property = type ? type : 'items';
      setActivitiesChart((prevState) => ({ ...prevState, loading: true }));
      const chartData = await getSeriesByTypeAction(params);
      if (chartData instanceof Error) {
        setActivitiesChart((prevState) => ({ ...prevState, error: chartData?.message }));
      } else {
        setActivitiesChart((prevState) => ({
          ...prevState,
          count: chartData?.results?.length,
          [property]: chartData?.results,
        }));
        setIsEmpty(chartData.results);
      }
      setActivitiesChart((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      // handle error
    }
  };

  const getCountersData = async ({ params }, chartType, status) => {
    try {
      const countersData = await getAgregationAction(params);
      if (countersData instanceof Error) {
        setCounters((prevState) => ({
          ...prevState,
          [chartType.toLowerCase()]: {
            ...prevState[chartType.toLowerCase()],
            error: countersData.message,
          },
        }));
      } else {
        setCounters((prevState) => ({
          ...prevState,
          [chartType.toLowerCase()]: {
            ...prevState[chartType.toLowerCase()],
            [status.toLowerCase()]: countersData.results,
          },
        }));
      }
    } catch (error) {
      // handle error
    }
  };

  const getAllCountersData = async () => {
    Object.keys(QUERIES_BY_TYPE).forEach((chartType) => {
      const chartCounter = QUERIES_BY_TYPE[chartType];
      getCountersData(
        {
          params: handleReqData({
            dataType: 'counters',
            dataQueries: chartCounter,
            status: STARTED_STATUS.VALUE,
          }),
        },
        chartCounter.TYPE,
        STARTED_STATUS.VALUE
      );
      getCountersData(
        {
          params: handleReqData({
            dataType: 'counters',
            dataQueries: chartCounter,
            status: FINISHED_STATUS.VALUE,
          }),
        },
        chartCounter.TYPE,
        FINISHED_STATUS.VALUE
      );
      if (chartType === VIDEOS_TYPE.VALUE.toLocaleLowerCase()) {
        getCountersData(
          {
            params: handleReqData({
              dataType: 'counters',
              dataQueries: chartCounter,
              status: WATCHED_STATUS.VALUE,
            }),
          },
          chartCounter.TYPE,
          WATCHED_STATUS.VALUE
        );
      }
      if (chartType === QUIZZES_TYPE.VALUE.toLocaleLowerCase()) {
        getCountersData(
          {
            params: handleReqData({
              dataType: 'counters',
              dataQueries: chartCounter,
              status: PASSED_STATUS.VALUE,
            }),
          },
          chartCounter.TYPE,
          PASSED_STATUS.VALUE
        );
      }
      if (chartType === ACTIVITIES_TYPE.VALUE.toLocaleLowerCase()) {
        getCountersData(
          {
            params: handleReqData({
              dataType: 'counters',
              dataQueries: chartCounter,
              status: OVERALL_STATUS.VALUE,
            }),
          },
          chartCounter.TYPE,
          OVERALL_STATUS.VALUE
        );
      }
    });
  };

  const getActiveUsersData = async ({ params }) => {
    setActiveUsersChart((prevState) => ({ ...prevState, loading: true }));
    setFetching(true);
    const activeUsersData = await getActiveUsersAction(params);
    if (activeUsersData instanceof Error) {
      setActiveUsersChart((prevState) => ({
        ...prevState,
        error: activeUsersData?.message,
        loading: false,
      }));
    } else {
      setActiveUsersChart((prevState) => ({
        ...prevState,
        loading: false,
        count: activeUsersData?.results?.length,
        items: activeUsersData?.results,
      }));
      setIsEmpty(activeUsersData.results);
    }
    setFetching(false);
  };

  const getEntitiesTableData = async ({ params }, entity?: string) => {
    setEntitiesTable((prevState) => ({ ...prevState, loading: true }));
    // TODO: check the use of activeUserData
    let activeUsersData;
    const current = entity ? entity : entitiesTable.currentEntity;

    try {
      if (current === TEAMS_ENTITY.VALUE) {
        activeUsersData = await getTeamsTableAction(params);
      }
      if (current === COURSES_ENTITY.VALUE) {
        activeUsersData = await getCoursesTableAction(params);
      }
      if (current === USERS_ENTITY.VALUE) {
        activeUsersData = await getUsersTableAction(params);
      }
      if (activeUsersData instanceof Error) {
        setEntitiesTable((prevState) => ({
          ...prevState,
          error: activeUsersData?.message,
          loading: false,
        }));
      } else {
        setEntitiesTable((prevState) => ({
          ...prevState,
          loading: false,
          count: activeUsersData?.results?.pagination?.total,
          items: activeUsersData?.results?.data,
          pageSize: activeUsersData?.results?.pagination.page_size,
          page: activeUsersData?.results?.pagination.page,
          currentEntity: current,
        }));
        setIsEmpty(activeUsersData.results);
      }
      setEntitiesTable((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setEntitiesTable((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const updateChart = (currentChart: string) => {
    setCurrentChart(currentChart);
  };

  const updateEntity = (currentValue?: string) => {
    getEntitiesTableData(
      { params: handleReqData({ dataType: 'table', page: '1', selected_entity: currentValue }) },
      currentValue
    );
  };

  const changeFilters = (newValue: IFilterItem) => {
    if (newValue.team_ids === '') {
      const newValueClone = cloneDeep(newValue);
      const teamsIds = [];
      user.teams.forEach((team) => team.is_manager && teamsIds.push(team.team_id));
      newValueClone.team_ids = teamsIds.join(',');
      setFilters(newValueClone);
    } else {
      setFilters(newValue);
    }
  };

  const changePagination = (value: { name: string; value: string }) => {
    getEntitiesTableData({
      params: handleReqData({ dataType: 'table', [value.name]: value.value }),
    });
  };

  const changeOrdering = (newValue: string) => {
    setOrdering(newValue);
  };

  useEffect(() => {
    getActiveUsersData({ params: handleReqData({ dataType: 'activeUsers' }) });
  }, [filters]);

  useEffect(() => {
    getActivitiesChartData({
      params: handleReqData({
        dataType: 'charts',
        dataQueries: QUERIES_BY_TYPE[currentChart],
        status: OVERALL_STATUS.VALUE,
      }),
    });
    getActivitiesChartData(
      {
        params: handleReqData({
          dataType: 'charts',
          dataQueries: QUERIES_BY_TYPE[currentChart],
          status: FINISHED_STATUS.VALUE,
        }),
      },
      'finished'
    );
    getActivitiesChartData(
      {
        params: handleReqData({
          dataType: 'charts',
          dataQueries: QUERIES_BY_TYPE[currentChart],
          status: STARTED_STATUS.VALUE,
        }),
      },
      'started'
    );
    if (currentChart === QUIZZES_TYPE.VALUE.toLowerCase()) {
      getActivitiesChartData(
        {
          params: handleReqData({
            dataType: 'charts',
            dataQueries: QUERIES_BY_TYPE[currentChart],
            status: PASSED_STATUS.VALUE,
          }),
        },
        'passed'
      );
    }
    getAllCountersData();
  }, [filters, currentChart, teams]);

  useEffect(() => {
    getEntitiesTableData({ params: handleReqData({ dataType: 'table', page: '1' }) });
  }, [filters, tableOrdering, currentChart]);

  const handleReqData = (dataQuery: {
    dataType: any;
    status?: any;
    dataQueries?: any;
    page?: string;
    page_size?: string;
    selected_entity?: string;
  }) => {
    let params = {
      date_to: filters.date_to,
      date_from: filters.date_from,
      period: filters.period,
      team_ids: filters.team_ids,
      user_id: uaa_id,
      account_id: business_account_id,
      ordering: tableOrdering,
    };

    if (!params.team_ids) {
      const teamsIds = [];
      if (!isAccountOwner) {
        user.teams.forEach((team) => team.is_manager && teamsIds.push(team.team_id));
      }
      params.team_ids = teamsIds.join(',');
    }

    if (dataQuery.dataType === 'table') {
      const newparams = ENTITY_COLUMN_ACTIONS[entitiesTable.currentEntity].reduce((acc, column) => {
        acc[column.key] = QUERIES_BY_TYPE[currentChart][column.actions].ACTIONS;
        if (currentChart === 'videos') {
          acc['column_external_action'] =
            dataQuery.selected_entity === 'courses'
              ? 'videos_watched_time_courses'
              : 'videos_watched_time_users';
        }
        if (currentChart === 'quizzes') {
          acc['column_metadata'] = 'passed:true';
        }
        return acc;
      }, {});
      params = { ...params, ...newparams };
      params['page'] = dataQuery.page ? dataQuery.page : entitiesTable.page;
      params['page_size'] = dataQuery.page_size ? dataQuery.page_size : entitiesTable.pageSize;
    }
    if (dataQuery.dataType === 'charts') {
      params['actions'] = dataQuery.dataQueries[dataQuery.status].ACTIONS;
      params['external_action'] = dataQuery.dataQueries[dataQuery.status].EXTERNAL_ACTION;
      params['metadata'] = dataQuery.dataQueries[dataQuery.status].METADATA;
    }
    if (dataQuery.dataType === 'counters') {
      params['actions'] = dataQuery.dataQueries[dataQuery.status].ACTIONS;
      params['external_action'] = dataQuery.dataQueries[dataQuery.status].EXTERNAL_ACTION;
      params['metadata'] = dataQuery.dataQueries[dataQuery.status].METADATA;
    }
    return params;
  };

  return {
    fetching: fetching,
    isEmpty,
    filters,
    tableOrdering,
    error: false,
    activitiesChart,
    activeUsersChart,
    entitiesTable,
    counters,
    currentChart,
    updateChart,
    updateEntity,
    changeFilters,
    changePagination,
    changeOrdering,
  };
};

export default useAnalyticsContent;
