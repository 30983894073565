import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { COLOR_CHART_STATUSES } from 'constants/analytics';
import { IFullCHartData } from 'contexts/analytics/types';

interface Props {
  chartType: string;
  items: IFullCHartData[];
  Xaxis: string;
  Yaxis: string;
  dataDisplay: { key: string; checked?: boolean; fixed?: boolean }[];
}

const ActiveUsersChart = (data: Props): JSX.Element => {
  return (
    <>
      {data.chartType === 'BarChart' ? (
        <ResponsiveContainer width={'90%'} height={400}>
          <BarChart width={500} height={300} data={data.items}>
            <XAxis dataKey={data.Xaxis} />
            <YAxis dataKey={data.Yaxis} />
            <CartesianGrid strokeDasharray="3 3" />
            {data.dataDisplay.map((bar) => {
              if (bar.checked || bar.fixed) {
                return (
                  <Bar
                    key={bar.key}
                    dataKey={bar.key === 'overall' ? 'count' : bar.key}
                    stackId={bar.key}
                    fill={COLOR_CHART_STATUSES[bar.key.toUpperCase()].fill}
                  />
                );
              }
            })}
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <ResponsiveContainer width="98%" height={400}>
          <AreaChart
            width={500}
            height={300}
            data={data.items}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="6 6" />
            <XAxis dataKey={data.Xaxis} />
            <YAxis dataKey={data.Yaxis} />
            <Tooltip />
            {data.dataDisplay.map((area) => {
              if (area.checked || area.fixed) {
                return (
                  <Area
                    key={area.key}
                    type="monotone"
                    dataKey={area.key === 'overall' ? 'count' : area.key}
                    stroke={COLOR_CHART_STATUSES[area.key.toUpperCase()].stroke}
                    fill={COLOR_CHART_STATUSES[area.key.toUpperCase()].fill}
                  />
                );
              }
            })}
          </AreaChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default ActiveUsersChart;
