import { useContext, useMemo } from 'react';
import { isDesktop, isMobile, isTablet } from 'react-device-detect';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import AsideBusiness from './Business';
import ineLogo from 'assets/images/logo.png';
import './aside.scss';
import { SessionContext } from 'contexts/session';
import Tooltip from 'components/tooltip';
import { screenMd, screenSm } from 'constants/styles/breakpoints';
import { validateRole } from 'contexts/session/utilities';
import useAccountPlanData from 'hooks/useAccountPlanData';
import { MENU_ITEMS } from 'constants/menu';

const Container = styled.div<{ translateY: string }>`
  position: fixed;
  display: flex;
  top: unset;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  flex-direction: row;
  background-color: ${({ theme }) => theme.default.colors.aside.bg};
  z-index: 900;
  transition: top 0.3s;

  ${screenMd} {
    flex-direction: column;
    left: 0;
    top: 0;
    bottom: 0;
    width: 140px;
  }

  ${({ translateY }) =>
    translateY &&
    `
    ${screenMd} {
      top: ${translateY}
    }
  `}
`;

const Logo = styled.div`
  display: none;
  ${screenMd} {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 64px;
  }
`;

const LogoImage = styled.img`
  width: 92px;
  height: 28px;
  object-fit: contain;
  object-position: center;
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  padding: 0px 24px;
  height: 70px;
  justify-content: space-around;
  ${screenSm} {
    min-height: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  ${screenMd} {
    justify-content: center;
    flex-direction: column;
    padding: 0px;
    padding-top: 12.5px;
    height: auto;
    min-height: auto;
    flex-grow: 1;
    justify-content: start;
  }
`;

const NavMenuItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 47px;
  height: 45px;
  margin: 7px;
  ${screenSm} {
    width: auto;
    height: auto;
    margin: 0px;
    padding-top: 10px;
  }
  ${screenMd} {
    margin-top: auto;
  }
`;

const NavMenuLink = styled(NavLink)<{ disabled?: boolean; cursor: string }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 47px;
  height: 45px;
  margin: 7px;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.default.colors.aside.menu.labels};
  cursor: ${({ cursor }) => cursor} !important;
  opacity: 0.7;
  border-radius: 5px;
  transition: opacity 0.4s;

  svg {
    width: 25px;
    height: 25px;
    margin-top: 0px;
    color: ${({ theme }) => theme.default.colors.aside.menu.icons};
  }

  &.selected {
    opacity: 1;
    font-weight: 700;
    background-color: ${({ theme }) => theme.default.colors.aside.menu.bg};
  }

  &:hover,
  &:active,
  &:focus {
    transition: opacity 0.4s;
    opacity: 1;
    background-color: ${({ theme }) => theme.default.colors.aside.menu.bg};
    color: ${({ theme }) => theme.default.colors.aside.menu.labels};
  }
  ${({ disabled }) =>
    disabled &&
    `
    span {
      color: #57575C;
    }
    svg {
      opacity: 0.3;
    }
  `}

  span {
    display: none;
  }

  ${screenMd} {
    width: 115px;
    height: 65px;
    margin: 7.5px auto;
    span {
      display: inherit;
    }
    svg {
      height: auto;
      width: 20px;
      margin-top: 6px;
    }
  }
`;

const GetNavItems = ({ items }): JSX.Element => {
  const { account } = useContext(SessionContext);
  const { isExpired, isPastDue } = useAccountPlanData({ account });

  return (
    <>
      {items.map(
        ({ Icon, text, to }, index: number) =>
          text !== 'Account' && (
            <Tooltip
              placement="right"
              title={isExpired ? 'Your account is expired' : text + ' page'}
              key={index}
            >
              <NavMenuLink
                disabled={text !== 'Dashboard' && (isExpired || isPastDue)}
                cursor={isExpired ? 'not-allowed' : 'pointer'}
                to={to}
                activeClassName="selected"
                exact
                onClick={(e) => {
                  if (text !== 'Dashboard' && isExpired) e.preventDefault();
                }}
              >
                <Icon />
                <span>{text}</span>
              </NavMenuLink>
            </Tooltip>
          )
      )}
    </>
  );
};

const Aside = ({ translateY }: { translateY: string }): JSX.Element => {
  const isRaxEnvEnabled = process.env.REACT_APP_RAX_RENTALS_ENABLED === 'True';
  const {
    account,
    user,
    showDrawer,
    isRaxEnabled: isAccountRaxEnabled,
  } = useContext(SessionContext);
  const asideMenuItems = useMemo(() => {
    if (isRaxEnvEnabled) return Object.values(MENU_ITEMS);
    return Object.values(MENU_ITEMS).filter(({ text }) => text !== MENU_ITEMS.RAX.text);
  }, []);

  const isRaxEnabled = useMemo(
    () => isAccountRaxEnabled && isRaxEnvEnabled,
    [isAccountRaxEnabled, isRaxEnvEnabled]
  );

  const showAccountsManagement =
    user &&
    validateRole({
      roles: user?.uaa_data.roles.data.map(({ id }) => id),
      email: user?.email,
    });

  const asideUpdatedItems = useMemo(() => {
    const { ACCOUNTS, USERS, RAX } = MENU_ITEMS;
    const adminItems = [ACCOUNTS.text, USERS.text, RAX.text];
    const hiddenItems = [ACCOUNTS.text, !isRaxEnabled && RAX.text];
    if (!showAccountsManagement)
      return asideMenuItems.filter(({ text }) => !hiddenItems.includes(text));
    return asideMenuItems
      .filter(({ text }) => adminItems.includes(text))
      .sort((a, b) => adminItems.indexOf(a.text) - adminItems.indexOf(b.text));
  }, [showAccountsManagement, isRaxEnabled]);

  return (
    <Container translateY={!showDrawer && translateY}>
      {(!isMobile || isTablet) && (
        <Logo>
          <LogoImage src={ineLogo} alt="INE" />
        </Logo>
      )}

      <NavMenu>
        {user && <GetNavItems items={asideUpdatedItems} />}

        {isMobile && (
          <NavMenuItem>
            <AsideBusiness account={account} />
          </NavMenuItem>
        )}
      </NavMenu>

      {isDesktop && (
        <NavMenuItem>
          <AsideBusiness account={account} />
        </NavMenuItem>
      )}
    </Container>
  );
};

export default Aside;
