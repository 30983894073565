import { screenSm } from 'constants/styles/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const Card = styled.div`
  background-color: #fff;
  border: 1px solid #e9e9ed;
  border-radius: 10px;
  padding: 10px;
  flex-grow: 1;

  ${screenSm} {
    flex-grow: 0;
  }
`;

export const CardQuantity = styled.span`
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #1a2535;
  text-align: center;

  ${screenSm} {
    font-size: 42px;
    line-height: 38px;
  }
`;
export const Label = styled.label<{ color: string }>`
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: #57575c;
  display: flex;
  align-items: center;
  text-transform: capitalize;

  &:before {
    content: '';
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: ${({ color }) => color};
    display: inline-block;
    margin-right: 4px;
  }
`;
