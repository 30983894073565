import { IconTeam } from 'components/teamSelect/IconTeam';
import { TEAMS_ENTITY, USERS_ENTITY } from 'constants/analytics';
import { DEFAULT_IMG_USERS } from 'constants/urls';
import { ITableCellData } from 'contexts/analytics/types';
import { randomColor } from 'utils/colors';
import { LetterAvatar, UserImage } from './styles';

const TableCellContent = ({
  index,
  item,
  column,
  entity,
  showValues,
  card,
}: ITableCellData): JSX.Element => {
  return (
    <>
      <div className={'entities-table__table-body-row'}>
        {index === 0 &&
          (entity === USERS_ENTITY.VALUE ? (
            item?.user?.avatar && item?.user?.avatar !== DEFAULT_IMG_USERS ? (
              <UserImage src={item?.user?.avatar} />
            ) : (
              item && (
                <LetterAvatar color={randomColor(item?.user?.email)} card={card}>
                  {column.value(item)[0]}
                </LetterAvatar>
              )
            )
          ) : entity === TEAMS_ENTITY.VALUE ? (
            item?.team?.icon_name && item?.team?.icon_color ? (
              <IconTeam
                iconName={item?.team?.icon_name}
                color={item?.team?.icon_color}
                size={'small'}
                className="entities_icon_container"
              />
            ) : (
              <IconTeam
                iconName="group"
                color="blue"
                size={'small'}
                className={card && 'card-icon-container'}
              />
            )
          ) : (
            <></>
          ))}
        {(index === 0 || showValues) && item && (
          <div className={column.name + '__value'}>
            {column.value(item)}
            {column.scndValue && item && (
              <div className={column.name + '__scnd'}>{column.scndValue(item)}</div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default TableCellContent;
