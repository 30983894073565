import styled from 'styled-components';
import { useContext, useState, useEffect } from 'react';
import { AnalyticsContext } from 'contexts/analytics';
import { ICounters, IChart, IEntitiesTableData } from 'contexts/analytics/types';
import AnalyticsCounters from './AnalyticsCounters';
import './custom.scss';
import { screenSm } from 'constants/styles/breakpoints';
import EntitiesTable from './AnalyticsEntitiesTable';
import AnalyticsActivitiesChart from './AnalyticsActivitiesChart';
import MetricTabs from './MetricTabs';
import {
  ACTIVITIES_TYPE,
  LABS_TYPE,
  QUIZZES_TYPE,
  SLIDES_TYPE,
  VIDEOS_TYPE,
} from 'constants/analytics';

const ChartPanelContainer = styled.div`
  display: flex;
  box-sizing: border-box;

  flex-direction: column;

  ${screenSm} {
    flex-direction: row;
    padding: 30px;
    margin: 10px 0px;
  }
`;
const ContentTable = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  opacity: 1;
  transition: opacity 0.4s;
  height: 100%;
  background-color: #f2f2f7;
  padding-top: 10px;

  ${screenSm} {
    background-color: #fff;
    padding: 20px;
    margin-top: 20px;
  }
`;

interface IActiveUsersStatsParams {
  actions: {
    onUpdateChart: (tab: string) => void;
  };
  tabSelected: string;
  counters: ICounters;
  activities: IChart;
  entitiesTable: IEntitiesTableData;
}

const ActiveUsersStatsPanel = (data: IActiveUsersStatsParams): JSX.Element => {
  const { currentChart } = useContext(AnalyticsContext);
  const [dataDisplay, setDataDisplay] = useState([]);

  const changeTab = (tab: string) => {
    data.actions.onUpdateChart(tab);
  };

  const toggleCheckbox = (key: string) => {
    setDataDisplay((prevState) => {
      const newState = [...prevState];

      const index = newState.findIndex((o) => o.key === key);

      newState[index].checked = !newState[index].checked;

      return newState;
    });
  };

  useEffect(() => {
    setDataDisplay(() => {
      switch (currentChart.toUpperCase()) {
        case ACTIVITIES_TYPE.VALUE: {
          return [
            { key: 'overall', checked: true, fixed: true },
            { key: 'started', checked: false, fixed: false },
            { key: 'finished', checked: false, fixed: false },
          ];
        }
        case VIDEOS_TYPE.VALUE: {
          return [
            { key: 'started', checked: true, fixed: true },
            { key: 'finished', checked: false, fixed: false },
          ];
        }
        case QUIZZES_TYPE.VALUE: {
          return [
            { key: 'started', checked: true, fixed: true },
            { key: 'finished', checked: false, fixed: false },
            { key: 'passed', checked: false, fixed: false },
          ];
        }
        case LABS_TYPE.VALUE: {
          return [
            { key: 'started', checked: true, disabled: true },
            { key: 'finished', checked: false, disabled: false },
          ];
        }
        case SLIDES_TYPE.VALUE: {
          return [
            { key: 'started', checked: true, disabled: true },
            { key: 'finished', checked: false, disabled: false },
          ];
        }
      }
    });
  }, [currentChart]);
  return (
    <>
      <MetricTabs counters={data.counters} changeTab={changeTab} />
      <ChartPanelContainer>
        <AnalyticsCounters
          displayCounters={dataDisplay.length ? dataDisplay.map(({ key }) => key) : []}
        />
        <AnalyticsActivitiesChart toggleCheckbox={toggleCheckbox} dataDisplay={dataDisplay} />
      </ChartPanelContainer>
      <ContentTable>
        <EntitiesTable />
      </ContentTable>
    </>
  );
};

export default ActiveUsersStatsPanel;
