import { Container, Card, CardQuantity, Label } from './styles';

interface Props {
  quantity: number;
  label: string;
  color: string;
}

const CounterCard = ({ quantity, label, color }: Props): JSX.Element => {
  return (
    <Card>
      <Container>
        <CardQuantity>{quantity || 0}</CardQuantity>
        <Label color={color}>{label}</Label>
      </Container>
    </Card>
  );
};

export default CounterCard;
