import { useContext, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { AnalyticsContext } from 'contexts/analytics';
import SlideButtonsGroup from '../SlideButtonsGroup';
import ChartDataSelector from '../ChartDataSelector';
import { Container } from './styles';
import './statics.scss';
import ActiveUsersChart from '../ActiveUsersChart';
import { Spinner } from 'components/loadings';

const chartTypes = [
  { icon: 'LineChart', value: 'AreaChart' },
  { icon: 'BarChart', value: 'BarChart' },
];

interface Props {
  dataDisplay: { key: string; checked?: boolean }[];
  toggleCheckbox: (key: string) => void;
}

const AnalyticsActivitiesChart = ({ toggleCheckbox, dataDisplay }: Props): JSX.Element => {
  const [chartType, setChartType] = useState('AreaChart');
  const { activities } = useContext(AnalyticsContext);
  const chartItems = activities?.items?.map(({ date, count }) => {
    const finished = activities.finished?.find((i: { date: string }) => i.date === date);
    const passed = activities.passed?.find((i: { date: string }) => i.date === date);
    const started = activities.started?.find((i: { date: string }) => i.date === date);
    return {
      date,
      count,
      finished: finished ? finished.count : 0,
      started: started ? started.count : 0,
      passed: passed ? passed.count : 0,
    };
  });
  const updateChartType = (value: string) => {
    setChartType(value);
  };
  return (
    <>
      <Spinner
        active={activities.loading}
        top={isMobile ? '250px' : '300px'}
        left="33%"
        position="relative"
      />
      <Container lock={activities.loading}>
        <div className="activities-chart__options-container">
          <ChartDataSelector
            className="desktop-only"
            toggleCheckbox={toggleCheckbox}
            options={dataDisplay}
          />
          <SlideButtonsGroup action={updateChartType} options={chartTypes} current={chartType} />
        </div>
        <ActiveUsersChart
          items={chartItems}
          chartType={chartType}
          Xaxis="date"
          Yaxis="count"
          dataDisplay={dataDisplay}
        />
        <div className="activities-chart__options-container activities-chart__options-container-mob">
          <ChartDataSelector
            className="mobile-only"
            toggleCheckbox={toggleCheckbox}
            options={dataDisplay}
          />
        </div>
      </Container>
    </>
  );
};

export default AnalyticsActivitiesChart;
