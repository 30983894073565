import './statics.scss';
import Checkbox from 'components/checkbox';
import { capitalize } from 'utils/helpers';

interface FCProps {
  options: { key: string; checked?: boolean }[];
  toggleCheckbox: (value: string) => void;
  className: string;
}

const ChartDataSelector = ({ options, toggleCheckbox, className }: FCProps): JSX.Element => {
  return (
    <div className={`chart-data-selector ${className}`}>
      {options.map(({ key, checked }) => (
        <Checkbox
          key={key}
          className={`chart-data-selector__option-${key}`}
          checked={checked}
          onChange={() => toggleCheckbox(key)}
          controlled
          label={capitalize(key)}
        />
      ))}
    </div>
  );
};

export default ChartDataSelector;
