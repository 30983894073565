import styled from 'styled-components';
// import { screenSm } from 'constants/styles/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
`;

export const SectionCard = styled.section<{
  rounded?: boolean;
  shadow?: boolean;
  padding?: string;
  dashboard?: boolean;
}>`
  background: #fff;
  padding: 30px;

  ${({ padding }) =>
    padding &&
    `
		padding: ${padding};
	`}

  ${({ rounded }) =>
    rounded &&
    `
		border: 1px solid #E9E9ED;
		border-radius: 10px;
	`}

	${({ shadow }) =>
    shadow &&
    `
		box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
	`}

  ${({ dashboard }) =>
    dashboard &&
    `
    width: 100%;
    border: none;
	`}
`;
