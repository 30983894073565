import styled from 'styled-components';
import CounterCard from './CounterCard';
import { useContext } from 'react';
import { AnalyticsContext } from 'contexts/analytics';

import { COLOR_CHART_STATUSES } from 'constants/analytics';
import { screenSm } from 'constants/styles/breakpoints';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  background-color: #f2f2f7;
  padding: 10px;

  ${screenSm} {
    flex-direction: column;
    width: 202px;
    padding: 15px;
  }
`;

const AnalyticsCounters = ({ displayCounters }: { displayCounters: string[] }): JSX.Element => {
  const { counters, currentChart } = useContext(AnalyticsContext);
  return (
    <Container>
      {displayCounters.map((key) => (
        <CounterCard
          key={key}
          quantity={counters[currentChart][key]}
          label={key}
          color={COLOR_CHART_STATUSES[key.toUpperCase()].stroke}
        />
      ))}
    </Container>
  );
};

export default AnalyticsCounters;
