import { useContext } from 'react';
import ButtonIcon from 'components/buttonIcon';
import { Title, TitleWrapper, Subtitle, Content } from './styles';
import { useState } from 'react';
import { AnalyticsContext } from 'contexts/analytics';
import { Spinner } from 'components/loadings';
import ActiveUsersChart from '../ActiveUsersChart';
import { isMobile } from 'react-device-detect';
import './statics.scss';

const ActiveUsersCollapse = (): JSX.Element => {
  const { fetching, activeUsers } = useContext(AnalyticsContext);
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => {
    setCollapsed((prevValue) => !prevValue);
  };
  return (
    <>
      <header className="active-users-collapse__header" onClick={() => toggleCollapse()}>
        <TitleWrapper className="active-users-collapse__title-wrapper">
          <Title>Users with activity</Title>

          <ButtonIcon $terciary size="small" icon={collapsed ? 'ChevronUp' : 'ChevronDown'} />
        </TitleWrapper>
        <Subtitle>
          Number of users who have performed at least one individual activity per day within the
          last month
        </Subtitle>
      </header>
      {collapsed && (
        <>
          <Spinner active={fetching} top={isMobile ? '250px' : '300px'} position="relative" />
          <Content fetching={fetching}>
            <ActiveUsersChart
              items={activeUsers.items}
              chartType="Area"
              Xaxis="date"
              Yaxis="count"
              dataDisplay={[{ key: 'overall', checked: true }]}
            />
          </Content>
        </>
      )}
    </>
  );
};

export default ActiveUsersCollapse;
