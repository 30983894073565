import { Dispatch, SetStateAction, createContext, useEffect, useMemo, useState } from 'react';
import { PLAN_ENTERPRISE_PLUS_LAB, PLAN_LAB_EXPERIENCE } from 'constants/plans_config';
import {
  getAccessToken,
  getOriginalAccessToken,
  removeOriginalAccessToken,
  setAccessToken,
  setOriginalAccessToken,
} from '@internetworkexpert/js-common';
import { getAccountsDataAction, getSessionDataAction, getTeamsAction } from './actions';
import {
  isStaffMember,
  validateAccountOwner,
  validateTeamManager,
} from 'contexts/session/utilities';
import { useHistory, useLocation } from 'react-router-dom';

import AppReadyLoading from 'components/loadings/AppReadyLoading';
import { CONTENT_TYPES } from 'constants/playlists';
import { FCProps } from 'types/FCProps';
import Notification from 'components/notification';
import { PricingPlan } from 'contexts/pricingPlans/types';
import { Team } from 'contexts/teams/types';
import { UsersMe } from 'types/users';
import { getPricingPlansActions } from 'contexts/pricingPlans/actions';
import { impersonateService } from 'services/session';

// import useCookie from 'hooks/useCookie';

type Allows = {
  can_delete_user: boolean;
  can_unnasign_license: boolean;
};
interface Provider extends UsersMe {
  showDrawer?: boolean;
  setVisibiltyDrawer?: Dispatch<SetStateAction<boolean>>;
  showNotesDrawer?: boolean;
  setShowNotesDrawer?: Dispatch<SetStateAction<boolean>>;
  showActivityLogDrawer?: boolean;
  setShowActivityLogDrawer?: Dispatch<SetStateAction<boolean>>;
  licenses_available?: number | boolean;
  allows: Allows;
  updateLicensesAvailables?: (state: boolean, notRestrict?: boolean) => Promise<void>;
  isImpersonated: boolean;
  isStaff: boolean;
  isAccountOwner: boolean;
  isTeamManager: boolean;
  userRoleIds: string[];
  pricingPlans: PricingPlan[];
  isSonarEnabled: boolean;
  isRaxEnabled: boolean;
  hasSkillDive: boolean;
  hasLabExperience: boolean;
  actions: {
    updateAccountTeams: Dispatch<SetStateAction<Team[]>>;
    impersonate: (email?: string) => void;
    updateLicenses: (number: number | boolean) => void;
    refreshSession: () => void;
    refreshTeams: () => void;
    refreshAccounts: () => void;
  };
  userFeatures: { mark_as_completed: boolean };
  isMarkAsCompletedEnabled: boolean;
}

export const SessionContext = createContext<Provider | null>(null);

const SessionProvider = ({ children }: FCProps): JSX.Element => {
  const location = useLocation();
  const history = useHistory();
  const [teams, setTeams] = useState(null);
  const [sessionData, setSessionData] = useState(null);
  const [accountsData, setAccountsData] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showDrawer, setVisibiltyDrawer] = useState<boolean>(false);
  const [showNotesDrawer, setShowNotesDrawer] = useState<boolean>(false);
  const [showActivityLogDrawer, setShowActivityLogDrawer] = useState<boolean>(false);
  const [licenses_available, setLicensesAvailables] = useState<number | boolean>(null);
  const [sessionReady, setSessionReady] = useState(false);
  const [isImpersonated, setIsImpersonated] = useState(Boolean(getOriginalAccessToken()));
  const [pricingPlans, setPricingPlans] = useState(null);

  const userRoleIds = useMemo(
    () => sessionData?.user?.uaa_data?.roles?.data?.map(({ id }) => id) || [],
    [sessionData?.user?.uaa_data?.roles?.data]
  );
  const isStaff = useMemo(() => isStaffMember(userRoleIds), [userRoleIds]);
  const isAccountOwner = useMemo(() => validateAccountOwner(userRoleIds), [userRoleIds]);
  const isTeamManager = useMemo(() => validateTeamManager(userRoleIds), [userRoleIds]);

  useEffect(() => {
    setVisibiltyDrawer(false);
  }, [location]);

  useEffect(() => {
    teams &&
      sessionData?.account &&
      setSessionData((prev) => {
        return {
          ...prev,
          account: {
            ...prev.account,
            teams_count: teams.length,
          },
        };
      });
  }, [teams]);

  useEffect(() => {
    getSession();
  }, []);

  useEffect(() => {
    if (sessionData?.user && sessionData?.account?.id) {
      getTeams();
      getAccounts({ id: sessionData?.account?.id });
    }
  }, [sessionData?.account?.id]);

  useEffect(() => {
    sessionData?.account?.id &&
      setSessionData((prev) => {
        return {
          ...prev,
          account: {
            ...prev.account,
            licenses_available: licenses_available,
          },
        };
      });
  }, [licenses_available]);

  useEffect(() => {
    if (isStaff) {
      getPricingPlans();
    }
  }, [isStaff]);

  const updateLicensesAvailables = async (state: boolean, notRestrict: boolean) => {
    if (licenses_available === true) {
      return;
    }
    new Promise<void>((res, rej) => {
      const noR = Boolean(notRestrict);
      if (state) {
        licenses_available <= 0 ? rej() : setLicensesAvailables((prev: number) => prev - 1);
      } else {
        noR
          ? setLicensesAvailables((prev: number) => prev + 1)
          : licenses_available <= sessionData.account.licenses_available
          ? setLicensesAvailables((prev: number) => prev + 1)
          : rej();
      }
      res();
    });
  };

  // Login redirect if ine_access_token is not found.
  const getSession = async () => {
    setLoading(true);
    const data = await getSessionDataAction();
    setLoading(false);
    if (!(data instanceof Error)) {
      setSessionData(data);

      setLicensesAvailables(data?.account?.licenses_available);
      setSessionReady(true);
    }
  };

  const getAccounts = async ({ id }: { id: string }) => {
    setLoading(true);
    const data = await getAccountsDataAction({ id });
    if (!(data instanceof Error)) setAccountsData(data);
    setLoading(false);
  };

  const getPricingPlans = async () => {
    setLoading(true);
    const data = await getPricingPlansActions();
    if (!(data instanceof Error)) setPricingPlans(data);
    setLoading(false);
  };

  const getTeams = async () => {
    const teamsData = await getTeamsAction();
    if (!(teamsData instanceof Error)) {
      setTeams(teamsData);
    }
  };

  const onImpersonate = async (email?: string) => {
    if (email) {
      setLoading(true);
      try {
        const response = await impersonateService({ email });
        const responseData = response.data;
        response.status === 200 && handleImpersonate(responseData?.data.tokens.data.Bearer);
      } catch (error) {
        Notification({ type: 'error', text: 'Couldn’t impersonate' });
      }
    } else {
      handleImpersonate();
      setTeams(null);
    }
  };

  const handleImpersonate = async (newToken?: string) => {
    setLoading(true);
    if (newToken) {
      // impersonte start
      const accessToken = getAccessToken();
      setOriginalAccessToken(accessToken);
      setAccessToken(newToken);
    } else {
      // impersonate end
      const Bearer = getOriginalAccessToken();
      setAccessToken(Bearer);
      removeOriginalAccessToken();
    }
    await getSession();
    setIsImpersonated(Boolean(newToken));
    !newToken && history.push('/accounts');
  };

  const updateLicenses = (licenses) => {
    setLicensesAvailables(licenses);
  };

  const refresAccountsData = () => {
    getAccounts({ id: sessionData?.account?.id });
  };

  const hasLabExperience = useMemo(() => {
    const pricing_plan = sessionData?.account?.pricing_plan;

    if (!pricing_plan) return false;

    return pricing_plan === PLAN_LAB_EXPERIENCE || pricing_plan === PLAN_ENTERPRISE_PLUS_LAB;
  }, [sessionData?.account?.pricing_plan]);

  const hasSkillDive = useMemo(() => {
    const account_available_addons = sessionData?.account?.account_available_addons;

    return (
      account_available_addons?.filter((ad) => ad.name === CONTENT_TYPES.SKILL_DIVE).length > 0
    );
  }, [sessionData?.account?.account_available_addons]);

  return (
    <SessionContext.Provider
      value={{
        loading,
        user: sessionData?.user,
        userRoleIds,
        allows: {
          can_delete_user: isStaff || sessionData?.account?.can_delete_user,
          can_unnasign_license: isStaff || sessionData?.account?.can_unnasign_license,
        },
        userFeatures: { mark_as_completed: true },
        account: sessionData?.account,
        accounts: accountsData,
        teams,
        showDrawer,
        setVisibiltyDrawer,
        showNotesDrawer,
        setShowNotesDrawer,
        showActivityLogDrawer,
        setShowActivityLogDrawer,
        licenses_available,
        updateLicensesAvailables,
        isImpersonated,
        isStaff,
        isTeamManager,
        isAccountOwner,
        pricingPlans,
        isSonarEnabled: accountsData?.sonar_enabled,
        isRaxEnabled: accountsData?.rax_enabled,
        isMarkAsCompletedEnabled: sessionData?.enabled_user_features?.mark_as_completed,
        hasSkillDive,
        hasLabExperience,
        actions: {
          updateAccountTeams: setTeams,
          impersonate: onImpersonate,
          updateLicenses: updateLicenses,
          refreshSession: getSession,
          refreshTeams: getTeams,
          refreshAccounts: refresAccountsData,
        },
      }}
    >
      {sessionReady ? children : <AppReadyLoading />}
    </SessionContext.Provider>
  );
};

export default SessionProvider;
